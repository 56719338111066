import React, { useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { Link } from 'react-router-dom';
import { UserCircleIcon } from '@heroicons/react/20/solid';
import {
  useAcceptInterviewMutation,
  useInterviewerQueryMutation,
  useRejectInterviewMutation,
  useInterviewerRescheduleMutation,
} from '../../features/hiringstages/interviewApiSlice';
import { format, set } from 'date-fns';
import DateRangePicker from '../../components/common/DateRangePicker';
import LoadingIcon from '../../components/common/LoadingIcon';
const memoryOptions = [
  { name: 'Strong Hire', inStock: true },
  { name: 'Hire', inStock: true },
  { name: 'Need 2nd Openion', inStock: true },
  { name: 'No Hire', inStock: true },
];

function InterviewerCandList({ data }) {
  // console.log('data ', data);
  const textAreaRef = useRef(null);
  const [textArea, setTextArea] = useState('');

  const [acceptingDateTime, setAcceptingDateTime] = useState({});
  const [reScheduleDateTime, setReScheduleDateTime] = useState({});

  const [acceptingDateTimeError, setAcceptingDateTimeError] = useState('');

  const [acceptInterview, { isLoading: acceptInterviewLoading }] =
    useAcceptInterviewMutation();
  const [rejectInterview, { isLoading: rejectInterviewLoading }] =
    useRejectInterviewMutation();
  const [interviewerQuery, { isLoading: interviewerQueryLoading }] =
    useInterviewerQueryMutation();
  const [interviewerReschedule, { isLoading: interviewerRescheduleLoading }] =
    useInterviewerRescheduleMutation();


  // const initialValues = {
  //   id: interviewerCandListData?.id || 0,
  //   interview_id: interviewerCandListData?.interview_id || 0,
  // };

  const validationSchema = yup.object({
    evaluation_skill: yup.array().min(1, 'Please select atleast one skill'),
  });
  // const onSubmit = async (validationSchema, id, interviewid) => {
  // const onSubmit =
  //   ({ helo }) =>
  //   (values, formik) => {
  //     console.log('helo, values, formik -- ', helo, values, formik);
  //     // const data = { ...validationSchema, id, interviewId };
  //     // console.log('data', data);
  // try {
  //   const accountData = await acceptInterview(data).unwrap();
  // } catch (err) {
  //   console.log('err', err);
  // }
  //   };
  // const formik = useFormik({
  //   initialValues,
  //   validationSchema,
  //   onSubmit,
  //   enableReinitialize: true,
  // });

  async function rejectInterviewClick(e, id, interviewid) {
    // console.log(id, interviewid)
    try {
      const accountData = await rejectInterview({
        id: id,
        interviewid: interviewid,
      }).unwrap();
    } catch (err) { }
  }

  async function approveIntreview(e, id, interviewid) {
    // console.log(id, data[0].matched_approved_slot)
    // Find the entry in the array with the matching id
    const matchedEntry = data.find(item => item.id === id);
    // console.log(matchedEntry)
    if (matchedEntry && matchedEntry.matched_approved_slot) {
      // Extract the values immediately
      const acceptingDateTimeDetails = {
        interview_date: matchedEntry.matched_approved_slot.interview_date,
        interview_time_start: matchedEntry.matched_approved_slot.interview_time_start,
        interview_time_end: matchedEntry.matched_approved_slot.interview_time_end,
        level: data[0].level
      };

      // Log the values immediately before setting the state

      try {
        // console.log(acceptingDateTimeDetails);
        const accountData = await acceptInterview({
          ...acceptingDateTimeDetails,
          id: id,
          interviewid: interviewid,
        }).unwrap();
        setAcceptingDateTimeError('');
      } catch (err) { }
      // Set the state afterward for any further use
      setAcceptingDateTime(acceptingDateTimeDetails);
    } else {
      // console.log(`No matched_approved_slot found for ID ${id}`);
      // console.log(acceptingDateTime)
      if (Object.keys(acceptingDateTime).length > 0) {
        try {
          const accountData = await acceptInterview({
            ...acceptingDateTime,
            id: id,
            interviewid: interviewid,
          }).unwrap();

          setAcceptingDateTimeError('');
        } catch (err) { }
      } else {
        alert('Please select atleast one date time');
        setAcceptingDateTimeError('Please select atleast one date time');
      }
    }
  }

  function handleSelectedDate(e) {
    // console.log(e.target.value)
    const { value } = e.target;
    const [start, end] = value.split(' ');

    setAcceptingDateTime({
      // interview_date: format(startDateTime, 'YYYY-MM-dd'),
      interview_date: format(new Date(start), 'yyyy-MM-dd'),
      interview_time_start: format(new Date(start), 'HH:mm:ss'),
      interview_time_end: format(new Date(end), 'HH:mm:ss'),
      level: data[0].level
    });
    // console.log(acceptingDateTime)
  }

  function handleReScheduleDate(dateTimeArr) {
    setReScheduleDateTime({
      // interview_date: format(startDateTime, 'YYYY-MM-dd'),
      interview_date: format(
        new Date(dateTimeArr[0].interview_time_start),
        'yyyy-MM-dd'
      ),
      interview_time_start: format(
        new Date(dateTimeArr[0].interview_time_start),
        'HH:mm:ss'
      ),
      interview_time_end: format(
        new Date(dateTimeArr[0].interview_time_end),
        'HH:mm:ss'
      ),
    });
  }

  const handleRequestQuery = async (e, id, interviewid) => {
    setTextArea('');
    try {
      const accountData = await interviewerQuery(
        {
          id: id,
          interviewid: interviewid,
          interviewer_query: textArea,
        },
        { refetchOnMountOrArgChange: true }
      ).unwrap();
    } catch (err) { }
  };

  const handleReSchedule = async (e, id, interviewid) => {
    try {
      const accountData = await interviewerReschedule({
        id: id,
        interviewid: interviewid,
        ...reScheduleDateTime,
      }).unwrap();
    } catch (err) { }
  };

  // ***** code by tist
  // const parsedData = data.map(item => ({
  //   slot_availablity: JSON.parse(item.slot_availablity)
  // }));

  // console.log(parsedData);

  return (
    <>
      {data?.map((list, i) => (
        <li
          key={i}
          className="relative col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg bg-white shadow-lg"
        >
          <div className=" ">
            <div className="bg-white rounded-md shadow-3xl border border-gray-100 px-6 py-4">
              <Link
                type="button"
                to={`/app/interviewrequest/${list.id}/${list?.status || 0}`}
                className="flex w-full justify-center rounded-3xl text-sm font-medium  shadow-smfocus:outline-none "
              >
                <div className="self-center">
                  {list.photo ? (
                    <img src={list.photo} className="w-20 h-20 rounded-full" />
                  ) : (
                    <UserCircleIcon
                      className="h-20 w-20 text-gray-400"
                      aria-hidden="true"
                    />
                  )}
                </div>
                <div className="min-w-0 flex-1 pt-2 px-4">
                  <h1 className="  flex font-semibold text-2xl text-blue-800">
                    {list.id} - {list.firstName} {list.lastName}
                  </h1>
                  <p className="flex  font-medium py-1  text-md text-gray-400">
                    {list.current_designation} - {list.current_company}
                  </p>
                </div>
                {/* </div> */}
              </Link>

              <div className=" flex border-b border-gray-300 pb-4 pt-0">
                <div className="min-w-0 flex-1   font-semibold">
                  <h1 className="  flex text-md mt-3 text-black">
                    {list.mobile}
                  </h1>
                  <p className="flex py-1 text-md text-black">{list.email}</p>
                </div>
                <div className="min-w-0 flex-1 px-4  ">
                  {/* <ul className="text-left text-md font-semibold pt-3">
                      <li>#28, Rosary Church Colony,</li>
                      <li>Houston,</li>
                      <li>Texas United States</li>
                    </ul> */}
                  <div className="text-left text-md font-semibold pt-3">
                    {list.address}
                  </div>
                </div>
              </div>

              <div className=" flex border-b border-gray-300 pb-4">
                <div className="min-w-0 flex-1  sfont-semibold">
                  <h1 className="  flex text-xl mt-3 text-black">
                    Fullstack Developer
                  </h1>
                  <p className="flex py-1 text-sm text-gray-400">
                    Chennai, India
                  </p>
                </div>
              </div>

              <div className="flex">
                <div className="flex mt-5">
                  <h1 className=" text-xl text-black font-semibold mr-3 ">
                    Stage
                  </h1>
                  <h1 className="bg-stageL1 px-2 py-1.5 text-white text-sm font-bold">
                    {list?.interview_stage?.sno}
                  </h1>
                  <h1 className=" text-xl text-black ml-3 font-semibold">
                    {list?.interview_stage?.title}
                  </h1>
                </div>
              </div>

              <div className=" flex pb-0 mt-2">
                <div className="min-w-0 flex-1  ">
                  <h1 className="  flex text-xl mt-3 text-black font-semibold">
                    Mode of Interview
                  </h1>
                  <p className="flex py-1 text-sm text-gray-400 font-medium">
                    {list.interview_mode}
                  </p>
                </div>
              </div>

              <div className=" flex  pb-0">
                <div className="min-w-0 flex-1  ">
                  <h1 className="  flex text-xl mt-3 text-black font-semibold">
                    Venue
                  </h1>
                  <p className="flex py-1 text-sm text-gray-400 font-medium">
                    {list.interview_detail}
                  </p>
                </div>
              </div>

              {list.interview_recommendation == null ||
                list.interview_recommendation == '' ? (
                <>
                  <form>
                    {/* onSubmit={formik.handleSubmit} */}
                    <div className="flex pb-0">
                      <div className="min-w-0 lex-1">
                        <h1 className="  flex text-xl mt-3 text-black font-semibold">
                          Time
                        </h1>
                        {list.slot_availablity && (list.slot_availablity).length > 0 ? (
                          (list.slot_availablity).map((slot, si) => {
                            let hasMatchedSlot = false
                            let isMatchedSlot = false
                            let isoFormatStart = null
                            let isoFormatEnd = null
                            // console.log(list.matched_approved_slot)
                            if (list.matched_approved_slot) {
                              // Combine the date and time
                              let combinedStartDateTime = `${list.matched_approved_slot.interview_date}T${list.matched_approved_slot.interview_time_start}`;
                              let combinedEndDateTime = `${list.matched_approved_slot.interview_date}T${list.matched_approved_slot.interview_time_end}`;

                              isoFormatStart = (new Date(combinedStartDateTime)).toISOString()
                              isoFormatEnd = (new Date(combinedEndDateTime)).toISOString()
                              // console.log(isoFormatStart, slot.interview_time_start)
                              // console.log(isoFormatEnd, slot.interview_time_end)

                              hasMatchedSlot = list.matched_approved_slot !== null && list.matched_approved_slot !== undefined;
                              // Determine whether this slot should be checked and disabled
                              isMatchedSlot = hasMatchedSlot &&
                                slot.interview_time_start === isoFormatStart
                              // &&
                              // slot.interview_time_end === isoFormatEnd
                            }
                            // console.log(isMatchedSlot, hasMatchedSlot)
                            // console.log(isoFormatStart, slot.interview_time_start)
                            return (
                              <div className="" key={si}>
                                <div className="flex items-start justify-start pt-1">
                                  {isMatchedSlot == true ? (
                                    <input
                                      id={`slot-${i}-${si}`}
                                      aria-describedby="10:00 am - 11:00 am"
                                      name={`slot-${i}`}
                                      type="radio"
                                      value={hasMatchedSlot ? `${isoFormatStart} ${isoFormatEnd}` : `${slot.interview_time_start} ${slot.interview_time_end}`}
                                      className={`h-4 w-4 rounded border-gray-300 text-indigo-600 
                                      focus:ring-indigo-500 cursor-pointer mt-1                                       
                                      }`}
                                      checked={isMatchedSlot}
                                      disabled={!hasMatchedSlot}
                                      onChange={handleSelectedDate}
                                    />

                                  ) : (
                                    <input
                                      id={`slot-${i}-${si}`}
                                      aria-describedby="10:00 am - 11:00 am"
                                      name={`slot-${i}`}
                                      type="radio"
                                      value={hasMatchedSlot ? `${isoFormatStart} ${isoFormatEnd}` : `${slot.interview_time_start} ${slot.interview_time_end}`}
                                      className={`h-4 w-4 rounded border-gray-300 text-indigo-600 
                                    focus:ring-indigo-500 cursor-pointer mt-1                                       
                                    }`}
                                      disabled={hasMatchedSlot}
                                      onChange={handleSelectedDate}
                                    />
                                  )
                                  }
                                  <div className="ml-3 text-md leading-6">
                                    <label
                                      htmlFor={`slot-${i}-${si}`}
                                      className={`cursor-pointer flex ${hasMatchedSlot ? 'text-gray-500' : 'text-black'
                                        }`}
                                    >
                                      <span className="mr-3">
                                        <span className="font-semibold">From</span>{' '}
                                        {format(
                                          new Date(slot.interview_time_start),
                                          'dd,MMM yyyy HH:mm a'
                                        )}{' '}
                                      </span>

                                      <span className="">
                                        <span className="font-semibold mr-2">
                                          To
                                        </span>{' '}
                                        {format(
                                          new Date(slot.interview_time_end),
                                          'dd,MMM yyyy HH:mm a'
                                        )}
                                      </span>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            )
                          })
                        ) : (
                          <p>No available slots</p>
                        )}
                        {/* {acceptingDateTimeError !== '' && (
                        <div className=" bg-red-600 font-semibold text-sm">
                          {acceptingDateTimeError}
                        </div>
                      )} */}
                      </div>
                    </div>

                    <div className="flex  pb-4 pt-0">
                      {/* <div className="min-w-0 flex-1 ">
                    <label
                      htmlFor="Date"
                      className="  flex text-xl mt-3 text-black font-semibold"
                    >
                      Date
                    </label>
                    <p className="flex py-1 text-sm text-gray-400 font-medium">
                      19/09/2022
                    </p>
                  </div> */}
                      <div className="min-w-0 flex-1">
                        <label
                          htmlFor="scheduled_by"
                          className="  flex text-xl mt-3 text-black font-semibold"
                        >
                          Scheduled by
                        </label>
                        <p className="flex py-1 text-sm text-gray-400 font-medium">
                          {list.scheduled_by}
                        </p>
                      </div>
                    </div>

                    <div className="flex flex-shrink-0 justify-between pb-4 border-b border-gray-300">
                      {list.interview_approval_status !== 1 && (
                        list.interview_approval_status !== 2 ? (
                          // rejectInterviewLoading ? (
                          //   <button
                          //     type="button"
                          //     disabled
                          //     className=" rounded-lg border border-red-600 bg-red-600 py-1 px-6 mr-3 text-sm font-medium text-white shadow-sm  focus:outline-none"
                          //   >
                          //     <LoadingIcon />
                          //     Rejecting...
                          //   </button>
                          // ) : (
                          <button
                            type="button"
                            className=" rounded-lg border border-red-600 bg-red-600 py-1 px-6 mr-3 text-sm font-medium text-white shadow-sm  focus:outline-none"
                            onClick={(e) =>
                              rejectInterviewClick(e, list.id, list.interview_id)
                            }
                          >
                            Reject
                          </button>
                        ) : (

                          <div className="text-red-600 font-extrabold text-base">
                            Rejected
                          </div>
                        )
                      )}
                      {list.interview_approval_status}
                      {list.interview_approval_status != 1 ? (
                        // acceptInterviewLoading ? (
                        //   <button
                        //     type="submit"
                        //     disabled
                        //     className="inline-flex justify-center rounded-lg border border-transparent bg-approve py-1 px-6 text-sm font-medium text-white shadow-sm  focus:outline-none"
                        //   >
                        //     <LoadingIcon />
                        //     Approving...
                        //   </button>
                        // ) : (
                        <button
                          type="button"
                          className="inline-flex justify-center rounded-lg border border-transparent bg-approve py-1 px-6 text-sm font-medium text-white shadow-sm  focus:outline-none"
                          onClick={(e) =>
                            approveIntreview(e, list.id, list.interview_id)
                          }
                        >
                          Approve
                        </button>
                      ) : (
                        //  )
                        <div className="text-green-600 font-extrabold text-base">
                          Approved
                        </div>
                      )}
                    </div>
                  </form>

                  <div>
                    <div className=" mt-2">
                      <label
                        htmlFor="Raise_query_about_candidate"
                        className="block text-lg font-medium text-gray-700 pb-2"
                      >
                        Raise query about candidate
                      </label>
                      <textarea
                        rows={4}
                        name={i}
                        id={i}
                        // ref={textAreaRef}
                        onChange={(e) => {
                          setTextArea(e.target.value);
                        }}
                        className="block w-full bg-inputbg appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                      >
                        {textArea}
                      </textarea>
                    </div>

                    <div className="flex flex-shrink-0 justify-center pb-4 border-b border-gray-300 pt-4">
                      <button
                        type="button"
                        className=" rounded-lg  bg-request py-2 px-10 mr-3 text-sm font-medium text-white shadow-sm  focus:outline-none"
                        onClick={(e) =>
                          handleRequestQuery(e, list.id, list.interview_id)
                        }
                      >
                        Request Query
                      </button>
                    </div>
                  </div>

                  <div className="mt-2">
                    <label
                      htmlFor="Request_rescheduling"
                      className="block text-lg font-medium text-gray-700 pb-2"
                    >
                      Request Rescheduling
                    </label>
                    <div className="flex justify-between">
                      <div className=" mt-2">
                        <label
                          htmlFor="preferrable_date"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Preferrable Date
                        </label>
                        <DateRangePicker
                          // singleDate
                          addDateTimeArray={handleReScheduleDate}
                        />
                      </div>
                    </div>
                    <div className="flex flex-shrink-0 justify-center pb-4 border-b border-gray-300 pt-4">
                      <button
                        type="button"
                        onClick={(e) =>
                          handleReSchedule(e, list.id, list.interview_id)
                        }
                        className=" rounded-lg  bg-request py-2 px-10 mr-3 text-sm font-medium text-white shadow-sm  focus:outline-none"
                      >
                        Request Reschedule
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <div className=" bg-green-600 text-white text-center py-10 text-xl uppercase font-bold">
                  Interview Completed
                </div>
              )}
            </div>
          </div>
        </li >
      ))
      }
    </>
  );
}

export default InterviewerCandList;
