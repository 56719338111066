import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useConfirmCandidateMutation } from '../../../../../../features/hiringstages/offerApiSlice'

export default function ConfirmationPage() {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const [confirmCandidate, { data, isLoading }] = useConfirmCandidateMutation();
    const [response, setResponse] = useState(null);
    const [showModal, setShowModal] = useState(false); // Modal state
    const [modalType, setModalType] = useState(null); // To differentiate between accept and decline modal
    const [tempResponse, setTempResponse] = useState(null); // To store temporary response

    const handleConfirm = (resp, type) => {
        // Temporarily store the response to open the modal and track type
        setTempResponse(resp);
        setModalType(type); // Set modal type (accept/reject)
        setShowModal(true); // Show the modal
    };

    const handleModalConfirm = async () => {
        try {
            const result = await confirmCandidate({ token, response: tempResponse });
            setResponse(result.data.confirmed);
            setShowModal(false); // Close the modal after confirming
        } catch (error) {
            console.error('Error confirming candidate', error);
        }
    };

    const handleModalCancel = () => {
        setShowModal(false); // Close the modal without confirming
        setTempResponse(null); // Reset temp response
    };

    return (
        <div style={styles.container}>
            {response == null && (
                <>
                    <h1 style={styles.heading}>Do you accept the job offer?</h1>
                    <div style={styles.buttonContainer}>
                        <button style={styles.acceptButton} onClick={() => handleConfirm('yes', 'accept')}>
                            Yes, I accept
                        </button>
                        <button style={styles.declineButton} onClick={() => handleConfirm('no', 'reject')}>
                            No, I decline
                        </button>
                    </div>
                </>
            )}

            {isLoading && <div>Submitting your response...</div>}

            {response === true && (
                <>
                    <h1 style={styles.successMessage}>Thank you for accepting the job offer!</h1>
                    <p style={styles.info}>We look forward to working with you. Please wait for your next confirmation mail.</p>
                </>
            )}

            {response === false && (
                <>
                    <h1 style={styles.errorMessage}>We’re sorry to see you decline the offer.</h1>
                    <p style={styles.info}>Best of luck in your future endeavors. If you change your mind, feel free to reach out.</p>
                </>
            )}

            {/* Modal Box */}
            {showModal && (
                <div style={styles.modalOverlay}>
                    <div style={styles.modal}>
                        <h2 style={{ color: modalType === 'accept' ? 'green' : 'red' }}>
                            {modalType === 'accept' ? 'Confirm Acceptance' : 'Confirm Rejection'}
                        </h2>
                        <p>
                            {modalType === 'accept'
                                ? 'Are you sure you want to accept the job offer?'
                                : 'Are you sure you want to decline the job offer?'}
                        </p>
                        <div style={styles.modalButtons}>
                            <button style={styles.confirmButton} onClick={handleModalConfirm}>Yes</button>
                            <button style={styles.cancelButton} onClick={handleModalCancel}>No</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

const styles = {
    container: {
        textAlign: 'center',
        marginTop: '100px',
    },
    heading: {
        fontSize: '24px',
        marginBottom: '20px',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        gap: '20px',
    },
    acceptButton: {
        backgroundColor: 'green',
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    declineButton: {
        backgroundColor: 'red',
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    successMessage: {
        fontSize: '24px',
        color: 'green',
    },
    errorMessage: {
        fontSize: '24px',
        color: 'red',
    },
    info: {
        marginTop: '10px',
        fontSize: '18px',
    },
    // Modal styles
    modalOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modal: {
        backgroundColor: 'white',
        padding: '20px',
        borderRadius: '8px',
        textAlign: 'center',
    },
    modalButtons: {
        marginTop: '20px',
        display: 'flex',
        justifyContent: 'center',
        gap: '10px',
    },
    confirmButton: {
        backgroundColor: 'green',
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
    cancelButton: {
        backgroundColor: 'gray',
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    },
};

