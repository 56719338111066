import React from 'react';
import { useState, Fragment } from 'react';

import { Disclosure } from '@headlessui/react';
import { PencilSquareIcon, CheckCircleIcon } from '@heroicons/react/24/outline';
import { useCandInterviewAlllistQuery } from '../../../../../features/hiringstages/interviewApiSlice';
import { useDispatch } from 'react-redux';
import { setCandId } from '../../../../../features/hiringstages/candidateInterviewSlice';
import { getStatusCode } from '../../../UserWSUtils';

const InterviewCandidateList = ({ toggleInterview, interviewId }) => {
  //   const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [currentValue, setCurrentValue] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [deptId, setDeptId] = useState(null);

  const dispatch = useDispatch();

  //   const [deleteAdminMyTaskUserPrevillage] =
  //     useDeleteAdminMyTaskUserPrevillageMutation();

  const openDrawerWindow = (mode, obj) => {
    if (mode === 'edit') {
      setEdit(true);
      setCurrentValue(obj);
    } else {
      setEdit(false);
      setCurrentValue(null);
    }
    setOpenDrawer(!openDrawer);
  };
  const closeDrawerWindow = () => {
    setOpenDrawer(!openDrawer);
  };
  const onSubmit = () => {
    alert('On submit');
    // closeDrawerWindow();
  };

  const showNotificationMsg = () => {
    setShowNotification(!showNotification);
  };

  const closeNotificationMsg = () => {
    setShowNotification(false);
  };

  const openModalWindow = (id) => {
    setDeptId(id);
    setOpenModal(!openModal);
  };
  const closeModalWindow = () => {
    setOpenModal(!openModal);
  };

  //   const onModalSubmit = async (deptId) => {
  //     try {
  //       const deleteAdminMytask = await deleteAdminMyTaskUserPrevillage(
  //         deptId
  //       ).unwrap();
  //       console.log(deleteAdminMytask.message);
  //       closeModalWindow();
  //       showNotificationMsg();
  //     } catch (error) {
  //       console.log(JSON.stringify(error));
  //     }
  //   };

  const onModalSubmit = () => {
    // setOpenModal(!openModal);
  };
  // const {
  //   data: interviewList,
  //   error: interviewListError,
  //   isLoading: interviewListLoading,
  //   isFetching: interviewListFetching,
  //   isSuccess: interviewListSuccess,
  // } = useInterviewQuery();

  const interviewList = [
    {
      candidate_name: 'Jonathan',
      job_title: 'Software Engineer',
      interview_stage: 'L4 Face to Face',
      previous_interviewer: 'John',
      interviewer_recommendation: '{<span>ds<span/>}',
      previous_interview_date: '05/08/2022',
      next_interview_date: '06/08/2022',
      overall_score: '50%',
      next_interviewer: 'Robin Williams',
      timeline_status: 'At Risk',
      interview_status: 'Feedback Pending',
    },
    {
      candidate_name: 'George',
      job_title: 'HR Manager',
      interview_stage: 'L1 Screening',
      previous_interviewer: 'Steve',
      interviewer_recommendation: 'Mathew',
      previous_interview_date: '05/08/2022',
      next_interview_date: '06/08/2022',
      overall_score: '10%',
      next_interviewer: 'Theodore',
      timeline_status: 'On Track',
      interview_status: 'Schedule Rejected',
    },
  ];

  const {
    data: interviewcandList,
    error: interviewcandListError,
    isLoading: interviewcandListLoading,
    isFetching: interviewcandListFetching,
    isSuccess: interviewcandListSuccess,
  } = useCandInterviewAlllistQuery(interviewId);

  console.log(interviewcandList)

  function getRecommendation(interview) {
    // console.log(interview)
    const currentInterviews = interview?.current_interview || [];
    const previousInterviews = interview?.previous_interview || [];
    // console.log(previousInterviews)
    // Check if any current interview recommendation is 3 or 4
    const hasRejectedCurrent = currentInterviews.some(
      (interview) => (interview.interview_recommendation === 4 || interview.status === 3)
    );
    // If any of the current or previous interviews have rejection recommendation (3 or 4)
    if (hasRejectedCurrent) {
      return getStatusName(3); // Interview rejected
    }

    const has2ndOpenion = currentInterviews.some(
      (interview) => interview.interview_recommendation === 3
    );
    // If any of the current or previous interviews have need 2nd opinion  recommendation (3 or 4)
    if (has2ndOpenion) {
      return getStatusName(1); // Interview again restarted
    }

    const hasReschduleCurrent = currentInterviews.some((interview) => interview.status === -1);
    if (hasReschduleCurrent) {
      return getStatusName(-1); // Re-scheduled Request
    }

    const hasQueryCurrent = currentInterviews.some((interview) => interview.status === 4);
    if (hasQueryCurrent) {
      return getStatusName(4); // Query raised by interviewer

    }
    if (interview.interview_cand_approval === 0) {
      return getStatusName(-2); // Candidate rejected Interview

    }

    if (interview.interview_cand_approval === 1) {
      return getStatusName(7); // Candidate Accepted Interview

    }
    // If all interviews are completed (status is 5)
    const allcurrentCompleted = currentInterviews.every((interview) => interview.status === 5);
    if (allcurrentCompleted) {
      // const allPreCompleted = previousInterviews.every((interview) => interview.status === 5);
      // console.log(previousInterviews.length)
      if (previousInterviews.length > 0)
        return getStatusName(5); // Interview completed
      else
        return getStatusName(6) //Ready for Next Level
    }
    else
      return getStatusName(interview?.current_interview?.[0]?.status || 0);
    // if (currentRec === 3 || currentRec === 4) {
    //   return getStatusName(3);
    // } else if (previousRec === 3 || previousRec === 4) {
    //   return getStatusName(3);
    // }
    // else if (currentRec === 1 || currentRec === 2 || previousRec === 1 || previousRec === 2) {
    //   return getStatusName(5);
    // } else if (previousRec === 1 || previousRec === 2) {
    //   return getStatusName(2);
    // } else if (currentRec != null) {
    //   return getRecomendCode(currentRec);
    // } else {
    //   return getStatusName(interview?.current_interview?.[0]?.status || 0);
    // }
  }

  const getRatingPercentage = (techskillsArray) => {
    if (techskillsArray != null) {
      if (techskillsArray.length > 0) {
        const totalRating = techskillsArray.reduce(
          (acc, current) => acc + current.rating,
          0
        );
        const averageRating = totalRating / techskillsArray.length;

        return averageRating * 10;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  const gotoInterviewProcess = (value, obj) => {
    if (obj?.id > 0) {
      dispatch(setCandId(obj.id));
    } else {
      dispatch(setCandId(0));
    }
    toggleInterview(value, obj);
  };

  const getRecomendCode = (code) => {
    if (code === 1) {
      return (
        // <span className="bg-purple-700 px-6 py-2 text-white">Strong Hire</span>
        <div className="flex justify-center">
          <div className="block rounded-md bg-purple-700 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            Strong Hire
          </div>
        </div>
      );
    } else if (code === 2) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md bg-purple-700 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            Hire
          </div>
        </div>
      );
      //  <span className="bg-purple-700 px-6 py-2 text-white">Hire</span>
    } else if (code === 3) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md bg-red-400 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            Need 2nd Openion
          </div>
        </div>
        // <span className="bg-red-400 px-3 py-2 text-white">
        //   Need 2nd Openion
        // </span>
      );
    } else if (code === 4) {
      return (
        <div className="flex justify-center">
          <div className="block rounded-md bg-red-400 px-3 py- text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-0">
            No Hire
          </div>
        </div>
      );

      // <span className="bg-red-600 px-3 py-2 text-white">No Hire</span>
    } else {
      return '-';
    }
  };


  const getStatusName = (statusId) => {
    // console.log(statusId)
    if (statusId === 0) {
      return (
        <span className=" text-black px-6 py-2 block w-full text-center">
          Yet to accept
        </span>
      );
    } else if (statusId === 1) {
      return (
        <span className="bg-blue-700 px-6 py-2 text-white block w-full text-center">
          Scheduled for Interview
        </span>
      );
    } else if (statusId === 2) {
      return (
        <span className="bg-lime-500 px-6 py-2 text-white block w-full text-center">
          Interview Accepted
        </span>
      );
    } else if (statusId === 3) {
      return (
        <span className="bg-red-600 px-3 py-2 text-white block w-full text-center">
          Interview rejected
        </span>
      );
    } else if (statusId === 4) {
      return (
        <span className="bg-purple-600 px-3 py-2 text-white block w-full text-center">
          Query raised by interviewer
        </span>
      );
    } else if (statusId === -2) {
      return (
        <span className="bg-red-600 px-3 py-2 text-white block w-full text-center">
          Candidate Rejected
        </span>
      );
    } else if (statusId === 7) {
      return (
        <span className="bg-orange-600 px-3 py-2 text-white block w-full text-center">
          Candidate Accepted
        </span>
      );
    } else if (statusId === 5) {
      return (
        <span className="bg-green-700 px-3 py-2 text-white block w-full text-center">
          Interview Completed
        </span>
      );
    } else if (statusId === 6) {
      return (
        <span className="bg-yellow-600 px-3 py-2 text-white block w-full text-center">
          Ready for Next Level
        </span>
      );
    } else if (statusId === -1) {
      return (
        <span className="bg-orange-600 px-3 py-2 text-white block w-full text-center">
          Re-scheduled Request
        </span>
      );
    } else {
      return '-';
    }
  };


  if (interviewcandListLoading) {
    return <div>Loading...</div>;
  }

  if (interviewcandListSuccess) {
    return (
      <Disclosure.Panel as="dd" className="mt-0 pr-0">
        <div className="text-base leading-7 text-gray-600">
          {/* <h3>Bottom</h3> */}
          <div className="flex">
            <div className=" gap-4 basis-full">
              <div className="bg-white shadow-3xl gap-4 rounded-md ">
                <div className="bg-white rounded-md shadow-3xl border border-gray-100">
                  {/* <Interviewinterview /> */}

                  <div className="px-4 sm:px-6 lg:px-8">
                    <div className="sm:flex sm:items-center"></div>

                    <div className="mt-0 mb-8 flow-root">
                      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-0 lg:px-0">
                          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-t-lg">
                            <table className="min-w-full divide-y divide-gray-300">
                              <thead className="bg-gray-100 text-left text-md font-bold text-gray-900">
                                <tr>
                                  <th
                                    scope="col"
                                    className="py-3.5 pl-4 pr-3  sm:pl-6"
                                  >
                                    Candidate Name
                                  </th>
                                  {/* <th scope="col" className="px-3 py-3.5 ">
                                    Job Title
                                  </th> */}
                                  <th scope="col" className="px-3 py-3.5 ">
                                    Interview Stage
                                  </th>
                                  <th scope="col" className="px-3 py-3.5 ">
                                    Interviewer
                                  </th>

                                  <th scope="col" className="px-3 py-3.5 ">
                                    Interviewer Recommendation
                                  </th>

                                  <th scope="col" className="px-3 py-3.5 ">
                                    Previous Interview Date
                                  </th>

                                  <th
                                    scope="col"
                                    className="px-3 py-3.5 text-center"
                                  >
                                    Next Interview Date
                                  </th>

                                  {/* <th scope="col" className="px-3 py-3.5 ">
                                    Overall Score
                                  </th> */}

                                  {/* <th scope="col" className="px-3 py-3.5 ">
                                    Next Interviewer
                                  </th> */}
                                  <th scope="col" className="px-3 py-3.5 ">
                                    Timeline Status
                                  </th>
                                  <th scope="col" className="px-3 py-3.5 ">
                                    Interview Status
                                  </th>
                                  <th scope="col" className="px-3 py-3.5 ">
                                    Action{' '}
                                  </th>
                                </tr>
                              </thead>
                              {interviewcandList?.data.length > 0 ? (
                                <tbody className="divide-y divide-gray-200 bg-white ">
                                  {interviewcandList.data.map(
                                    (interview, i) => (
                                      <>
                                        {/* <pre>{JSON.stringify(interview)}</pre> */}
                                        <tr key={i}>
                                          <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm  sm:pl-6 border-r-2">
                                            {interview.id} -{' '}
                                            {interview.fullName}
                                          </td>
                                          {/* <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-500 border-r-2">
                                        {interview.job_title}
                                      </td> */}

                                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-r-2">
                                            {/* {interview.interview_stage} */}
                                            {
                                              interview.current_interview[0]
                                                ?.interview_stage?.sno
                                            }{' '}
                                            -{' '}
                                            {
                                              interview.current_interview[0]
                                                ?.interview_stage?.title
                                            }
                                          </td>
                                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-r-2">
                                            {/* {interview.previous_interview[0]
                                              ?.interviewer_name || '-'} */}
                                            {interview.current_interview
                                              ? interview.current_interview
                                                .map(interviewItem => interviewItem.interviewer_name || '-')
                                                .join(', ')
                                              : '-'}
                                          </td>
                                          <td className="whitespace-nowrap text-center px-2 py-2 text-sm font-medium text-gray-900 border-r-2">
                                            {getRecomendCode(
                                              interview.current_interview.find(
                                                (int) => (int.interview_recommendation === 3 || int.interview_recommendation === 4)
                                              )?.interview_recommendation
                                              ?? interview.current_interview[0]?.interview_recommendation ?? '-'
                                            )}
                                          </td>
                                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-r-2">
                                            {interview.previous_interview[0]
                                              ?.interview_date || '-'}
                                          </td>
                                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-r-2">
                                            {interview.current_interview[0]
                                              ?.interview_date || '-'}
                                          </td>
                                          {/* <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-r-2">
                                            -
                                          </td> */}
                                          {/* <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-r-2">
                                            {interview.current_interview[0]
                                              ?.interviewer_name || '-'}
                                          </td> */}
                                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-r-2">
                                          {getStatusCode(interview.TaskStatus)}
                                          </td>
                                          <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 border-r-2">
                                            {getRecommendation(interview)}
                                            {/* {interview.current_interview[0]
                                              ?.interview_recommendation != null
                                              ? getRecomendCode(
                                                  interview.current_interview[0]
                                                    ?.interview_recommendation
                                                )
                                              : getStatusName(
                                                  interview.current_interview[0]
                                                    ?.status || 0
                                                )} */}
                                          </td>
                                          <td className="relative flex items-center justify-start whitespace-nowrap py-2 pl-3 text-right text-sm font-medium ">
                                            <PencilSquareIcon
                                              className="ml-1 mr-2 h-7 w-7 text-purple-500 cursor-pointer "
                                              aria-hidden="true"
                                              // onClick={() =>
                                              //   gotoAddSourcing('addsourcing', sourcing)
                                              // }
                                              onClick={() =>
                                                gotoInterviewProcess(
                                                  'interviewprocess',
                                                  interview
                                                )
                                              }
                                            />

                                            <CheckCircleIcon
                                              className="ml-1 mr-2 h-7 w-7 text-green-500 "
                                              aria-hidden="true"
                                              onClick={() => openModalWindow()}
                                            />
                                          </td>
                                        </tr>
                                      </>
                                    )
                                  )}
                                </tbody>
                              ) : (
                                <tbody className="divide-y divide-gray-200 bg-white ">
                                  <tr>
                                    <td
                                      colSpan={12}
                                      className="px-6 py-4  whitespace-nowrap text-sm text-black text-center font-semibold"
                                    >
                                      No Data Found
                                    </td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Disclosure.Panel>
    );
  }
};

export default InterviewCandidateList;
