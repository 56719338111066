import React, { useState } from 'react';
import { XCircleIcon } from '@heroicons/react/20/solid';
import { PlusIcon } from '@heroicons/react/24/outline';
import {
  useGetInterviewCandDetailsQuery,
  useScheduleInterviewMutation,
} from '../../../../../../../features/hiringstages/interviewApiSlice';
import { selectCandidateInterview } from '../../../../../../../features/hiringstages/candidateInterviewSlice';
import { useSelector } from 'react-redux';
import useTeamsUsers from '../../../../../../../hooks/useTeamsUsers';
import DatePicker from 'react-datepicker';
import { useFormik } from 'formik';
import * as yup from 'yup';
import 'react-datepicker/dist/react-datepicker.css';
import DateRangePicker from '../../../../../../../components/common/DateRangePicker';
import { Link } from 'react-router-dom';
import ModalData from '../../../../../../../components/common/ModalData';
import { AddKeySkill } from '../modal/AddKeySkill';
import { useSkillsQuery } from '../../../../../../../features/skillnew/skillnewApiSlice';
import AddSkillnew from '../../../../../../../features/skillnew/AddSkillnew';
import Drawer from '../../../../../../../components/common/Drawer';
import Notification from '../../../../../../../components/common/Notification';

function ScheduleInterview({ toggleInterview }) {
  const [openModalData, setOpenModalData] = useState(false);
  const [openModalDataValues, setOpenModalDataValues] = useState();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [currentValue, setCurrentValue] = useState(null);
  const { teamsUsers, teamsUsersLoading, teamsUsersSuccess } = useTeamsUsers();
  const [startDate, setStartDate] = useState(new Date());
  const candId = useSelector(selectCandidateInterview);

  const [selectedSkills, setSelectedSkills] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);

  const [scheduleInterview, { isLoading: loadingSchedule }] =
    useScheduleInterviewMutation();

  const {
    data: skillslist,
    error,
    isLoading: skillsLoading,
    isFetching,
    isSuccess: skillsSuccess,
  } = useSkillsQuery({ refetchOnMountOrArgChange: true });

  const getDateTimeValues = (dateTimeArr) => {
    setSelectedDates(dateTimeArr);
  };

  const {
    data: canddetails,
    isLoading,
    isSuccess,
  } = useGetInterviewCandDetailsQuery(candId, {
    refetchOnMountOrArgChange: true,
  });

  const openModalDataWindow = (sendtoModal) => {
    setOpenModalDataValues(sendtoModal);
    setOpenModalData(!openModalData);
  };

  const closeModalDataWindow = () => {
    setOpenModalData(false);
  };

  const onModalDataSubmit = (e) => {
    e.preventDefault();
    // showNotificationMsg();
  };

  const closeNotificationMsg = () => {
    setShowNotification(false);
  };

  const openDrawerWindow = (mode, obj) => {
    if (mode === 'edit') {
      setEdit(true);
      setCurrentValue(obj);
    } else {
      setEdit(false);
      setCurrentValue(null);
    }
    setOpenDrawer(!openDrawer);
  };
  const closeDrawerWindow = () => {
    setOpenDrawer(!openDrawer);
  };

  const selectedInterViewLevel =
    canddetails?.data.interview.length > 0
      ? canddetails?.data.interview[canddetails?.data.interview.length - 1]
        .interview_recommendation != null
        ? canddetails?.data.interview_level[canddetails?.data.interview.length]
        : canddetails?.data.interview_level[canddetails?.data.interview.length - 1]
      : 0;


  const initialValues = {
    candid: candId,
    level: selectedInterViewLevel?.id || 0,
    evaluation_skill: selectedIds,
    interviewer: 0,
    interview_date: '',
    slot_availablity: selectedDates,
    interview_mode: '',
    interview_detail: '',
    send_reminder: ['Interviewer', 'Candidate', 'Scheduler'],
    comment: '',
    feedback: '',
    interviewer: '',
  };

  const validationSchema = yup.object({
    evaluation_skill: yup.array().min(1, 'Please select atleast one skill'),
    slot_availablity: yup.array().min(1, 'Please select atleast one slot'),
    interviewer: yup.string().required('Please enter Interviewer'),
    interview_mode: yup.string().required('Please select Interview mode '),
    interview_detail: yup.string().required('Please enter Interview Details'),
    comment: yup.string().required('Please enter feedback'),
  });
  const onSubmit = async (validationSchema) => {
    try {
      const accountData = await scheduleInterview(validationSchema).unwrap();
      setShowNotification(true);
      toggleInterview();
      // onClose();--
    } catch (error) { }
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const getSkillName = (skillid) => {
    const skillName = skillslist?.data.find((element) => element.id == skillid);
    return skillName?.name;
  };

  const updateLocalSkillsId = (id) => {
    const isSelected = selectedIds.includes(id);
    if (isSelected) {
      setSelectedIds(selectedIds.filter((selectedId) => selectedId !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  if (isSuccess) {
    return (
      <>
        {canddetails?.data.interview_level.length ==
          canddetails?.data.interview.length ? (
          <div className="text-center text-green-600 font-bold bg-green-100 p-2 rounded">
            All Interviews completed
          </div>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <div className="flex ">
              <div className=" bg-gray-200 py-4 rounded-lg w-full">
                <div className="flex px-5 pb-3 items-center justify-between">
                  <div className="flex">
                    <span className="bg-screeningL3 px-2 py-1.5 text-white text-sm font-bold">
                      {selectedInterViewLevel?.sno || 'N/A'}
                    </span>
                    <h2 className="self-center pl-3">
                      {selectedInterViewLevel?.title || 'N/A'}
                    </h2>
                  </div>

                  <div className="flex">
                    <Link
                      type="button"
                      to={''}
                      className="flex mr-2 justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-6 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none "
                      onClick={() => openDrawerWindow()}
                    >
                      <PlusIcon
                        className="-ml-1 mr-2 h-5 w-5 text"
                        aria-hidden="true"
                      />
                      Add
                    </Link>
                    <Link
                      type="button"
                      to={''}
                      className="flex justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-6 text-sm font-medium text-white shadow-sm hover:bg-violet-700 focus:outline-none "
                      onClick={() =>
                        openModalDataWindow({
                          mode: 'Add',
                          title: 'Add Key Skill',
                          data: formik.values.evaluation_skill,
                        })
                      }
                    >
                      <PlusIcon
                        className="-ml-1 mr-2 h-5 w-5 text"
                        aria-hidden="true"
                      />
                      Add General Skill
                    </Link>
                  </div>
                </div>
                <div className="bg-white rounded-md shadow-3xl border border-gray-100 mx-5">
                  <div className=" py-5 px-5">
                    {formik.values.evaluation_skill.map((skillsId) => (
                      <span
                        key={skillsId}
                        className="inline-flex mr-4 items-left rounded-full bg-blue-100 px-3 py-3 text-md font-medium text-black"
                      >
                        {getSkillName(skillsId)}
                        <XCircleIcon
                          className="h-6 w-6 text-gray-900 ml-3"
                          aria-hidden="true"
                        />
                      </span>
                    ))}
                  </div>
                  {formik.touched.evaluation_skill &&
                    formik.errors.evaluation_skill ? (
                    <div className="mt-2 text-sm w-full text-red-600">
                      {formik.errors.evaluation_skill}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            {/* <DateRangePicker addDateTimeArray={getDateTimeValues} /> */}
            {/* {formik.touched.slot_availablity &&
            formik.errors.slot_availablity ? (
              <div className="mt-2 text-sm w-full text-red-600">
                {formik.errors.slot_availablity}
              </div>
            ) : null} */}

            {/* <div className="mt-6 grid grid-cols-4 gap-6 px-0">
              <div className="">
                <label
                  htmlFor="interviewer"
                  className="block text-sm font-medium text-gray-900"
                >
                  Interviewer
                </label>

                <select
                  name="interviewer"
                  id="interviewer"
                  autoComplete="interviewer"
                  className="bg-inputbg border border-inputBorder mt-1 block w-full rounded-md  py-2 px-3  focus:border-purplecolor-100 focus:outline-none sm:text-sm"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.interviewer}
                >
                  <option value="">-- Select interviewer --</option>
                  {teamsUsers?.data
                    .filter((user) => user.employee_type === 'Interviewer')
                    .map((user) => (
                      <option value={user.id} key={user.id}>
                        {user.first_name} {user.last_name}
                      </option>
                    ))}
                </select>
                {formik.touched.interviewer && formik.errors.interviewer ? (
                  <div className="mt-2 text-sm w-full text-red-600">
                    {formik.errors.interviewer}
                  </div>
                ) : null}
              </div>
            </div> */}

            <div className="flex flex-grow">
              <div className="basis-1/2 mt-5">
                <div className="flex justify-between">
                  <div>
                    <label
                      htmlFor="interviewer"
                      className="block text-md font-semibold text-gray-900"
                    >
                      Interviewer
                    </label>
                    <p className="text-sm pt-2">Johnson</p>
                    <p className="text-xs text-gray-400">Recruiter</p>
                  </div>
                  <div>
                    <label
                      htmlFor="interview_date"
                      className="block text-md font-semibold text-gray-900"
                    >
                      Interview Date
                    </label>
                    <p className="text-sm pt-2">10/10/2022</p>
                  </div>
                </div>
                <div className="mt-5">
                  <label
                    htmlFor="timeslot"
                    className="block text-md font-semibold text-gray-900"
                  >
                    Timeslot
                  </label>
                  <p className="text-sm pt-2">05:30 pm to 06:30 pm</p>
                  <p className="text-sm">07:30 pm to 08:30 pm</p>
                </div>

                <div className="mt-5">
                  <label
                    htmlFor="mode_of_interview"
                    className="block text-md font-semibold text-gray-900"
                  >
                    Mode of Interview
                  </label>
                  <p className="text-sm pt-2">Face to Face</p>
                </div>

                <div className="mt-5">
                  <label
                    htmlFor="Venue"
                    className="block text-md font-semibold text-gray-900"
                  >
                    Venue
                  </label>
                  <p className="text-sm pt-1">
                    Phase 1, Eco Space, Bellandur, Bengaluru
                  </p>
                </div>

                <div className="mt-5">
                  <label
                    htmlFor="feedback"
                    className="block text-md font-semibold text-gray-900"
                  >
                    Feedback
                  </label>
                  <p className="text-sm pt-1">
                    Candidate is ready to attend interview
                  </p>
                </div>
              </div>
            </div>
          </form>
        )}

        <Notification
          isShow={showNotification}
          onClose={() => closeNotificationMsg()}
        />

        <Drawer
          isOpen={openDrawer}
          title={isEdit ? 'Edit Skill' : 'Add Skill'}
          // title={isEdit ? '' : 'Add Skill'}
          onClose={() => closeDrawerWindow()}
          onSubmit={() => onSubmit()}
        >
          <AddSkillnew
            data={currentValue}
            isEdit={isEdit}
            onClose={() => closeDrawerWindow()}
          />
        </Drawer>

        <ModalData
          isModalDataOpen={openModalData}
          onModalDataClose={closeModalDataWindow}
        >
          <AddKeySkill
            onModalDataClose={() => closeModalDataWindow()}
            onModalDataSubmit={() => onModalDataSubmit()}
            // setShowNotification={() => showNotificationMsg()}
            data={openModalDataValues}
            updateSkillsArray={updateLocalSkillsId}
          />
        </ModalData>
      </>
    );
  }
}

export default ScheduleInterview;
