import { Routes, Route } from 'react-router-dom';
import './App.css';
import AuthLayout from './components/AuthLayout';
import Layout from './components/Layout';
import ProtectedAuth from './components/ProtectedAuth';

import Accountverify from './pages/auth/Accountverify';
import Accountactivate from './pages/auth/AccountActivate';
import Login from './pages/auth/Login';
import ForgotPassword from './pages/auth/ForgotPassword';
import Register from './pages/auth/Register';
import Changepassword from './pages/auth/Changepassword';

import Settings from './pages/settings/Settings';

import Account from './pages/settings/general/Account';
import Personal from './pages/settings/general/Personal';

import Department from './pages/settings/masterdata/Department';
import Branch from './pages/settings/masterdata/Branch';
import Skilltype from './pages/settings/masterdata/Skilltype';
import Location from './pages/settings/masterdata/Location';
import Skill from './pages/settings/masterdata/Skill';
import Designation from './pages/settings/masterdata/Designation';

import UserWorkspace from './pages/settings/privilege/UserWorkspace';
import AdminWorkspace from './pages/settings/privilege/AdminWorkspace';

import AddEmployee from './pages/team/AddEmployee';
import Team from './pages/team/Team';

import Workflow from './pages/workflow/Workflow';
import CreateWorkflow from './pages/workflow/CreateWorkflow';

import Dashboard from './pages/Dashboard';
import Interviewrequest from './pages/interviewrequest/Interviewrequest';
import InterviewDetails from './pages/interviewrequest/InterviewDetails';

import MyCalendar from './pages/interviewrequest/MyCalendar';

import PageNotFound from './pages/PageNotFound';

import OfferTemplate from './pages/OfferTemplate';
import AddSkilltype from './pages/AddSkilltype';

import EmailTemplate from './pages/EmailTemplate';

import AdminMytaskUserprivilege from './pages/AdminMytaskUserprivilege';

import UserWorkspacePage from './pages/userworkspace/UserWorkspacePage';

// import Wysiwyg from './components/common/Wysiwyg';
import WorkflowEdit from './pages/WorkflowEdit';

// import UserWorkspaceTab from './pages/userworkspace/UserWorkspaceTab';
import { useState } from 'react';
import WorkflowAssign from './pages/workflow/WorkflowAssign';
import WorkflowSignoffs from './pages/workflow/WorkflowSignoffs';
import AssignWorkflowTask from './pages/workflow/AssignWorkflowTask';
import SignoffsWorkflowTask from './pages/workflow/SignoffsWorkflowTask';
import AssignWorkflowSignoff from './pages/workflow/AssignWorkflowSignoff';
// import Verification from './pages/auth/Accountverify';

import TermsCondition from './pages/auth/TermsCondition';
import PrivacyPolicy from './pages/auth/PrivacyPolicy';
import ContactDetails from './pages/auth/ContactDetails';
import ErrorBoundary from './components/common/ErrorBoundary';

import ConfirmationPage from './pages/userworkspace/signoff/hiringstage/offer/candidate/confirmationPage';
import IntervieCandConfirmationPage from './pages/userworkspace/mytask/hiringstage/interview/candidate/interviwConformationPage'

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/confirm" element={<ConfirmationPage />} />
        <Route path="/interviewScheduled" element={<IntervieCandConfirmationPage />} />

        <Route name="Login" path="login" element={<Login />} />
        <Route path="forgotpassword" element={<ForgotPassword />} />
        <Route path="resetpassword" element={<Changepassword />} />
        <Route path="accountverify" element={<Accountverify />} />
        <Route
          path="accounts/register/verification"
          element={<Accountactivate />}
        />
        <Route path="*" element={<PageNotFound />} />

        <Route path="termscondition" element={<TermsCondition />} />
        <Route path="privacypolicy" element={<PrivacyPolicy />} />
        <Route path="contactdetails" element={<ContactDetails />} />

        <Route path="createworkflow" element={<CreateWorkflow />} />

        <Route element={<ProtectedAuth />}>
          <Route path="/app" element={<AuthLayout />}>
            {/* <Route path="userworkspacetab" element={<UserWorkspaceTab />} /> */}

            <Route path="interviewrequest" element={<Interviewrequest />} />
            <Route path="interviewrequest/:id/:stage" element={<InterviewDetails />} />
            <Route path="calendar" element={<MyCalendar />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="team" element={<Team />} />
            <Route path="team/addemployee" element={<AddEmployee />} />
            <Route path="team/editemployee/:id" element={<AddEmployee />} />
            <Route path="settings" element={<Settings />} />

            <Route path="settings/account" element={<Account />} />
            <Route path="settings/personal" element={<Personal />} />
            <Route path="settings/department" element={<Department />} />
            <Route path="settings/designation" element={<Designation />} />
            <Route path="settings/branch" element={<Branch />} />
            <Route path="settings/location" element={<Location />} />
            <Route path="settings/userprivilege" element={<UserWorkspace />} />
            <Route
              path="settings/adminprivilege"
              element={<AdminWorkspace />}
            />
            <Route path="settings/skilltype" element={<Skilltype />} />
            <Route path="settings/skill" element={<Skill />} />
            <Route path="settings/offertemplate" element={<OfferTemplate />} />
            <Route path="settings/addskilltype" element={<AddSkilltype />} />
            <Route path="settings/emailtemplate" element={<EmailTemplate />} />
            <Route path="settings/workflow" element={<Workflow />} />
            <Route path="settings/workflowedit" element={<WorkflowEdit />} />

            <Route path="createworkflow" element={<CreateWorkflow />} />
            <Route path="workflowassign" element={<WorkflowAssign />} />
            <Route path="workflowsignoffs" element={<WorkflowSignoffs />} />
            <Route
              path="assignworkflowsignoff/:id"
              element={<AssignWorkflowSignoff />}
            />
            <Route
              path="assignworkflowtask/:id"
              element={<AssignWorkflowTask />}
            />
            <Route
              path="signoffsworkflowtask"
              element={<SignoffsWorkflowTask />}
            />

            <Route
              path="adminworkspace"
              element={<AdminMytaskUserprivilege />}
            />

            <Route path="userworkspace" element={<UserWorkspacePage />} />

            {/* <Route
              path="adminmytaskuserprivilegessss"
              element={<AdminMytaskUserprivillagessss />}
            /> */}
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
