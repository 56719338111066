import { apiSlice } from '../../app/api/apiSlice';

export const interviewApiSlice = apiSlice.injectEndpoints({
  tagTypes: [
    'Interview',
    'candidatelistInterview',
    'InterviewApproval',
    'InterviewApproval',
  ],
  endpoints: (builder) => ({
    interviewerCandList: builder.query({
      query: () => ({
        url: '/interview/interviewer/candidates',
        method: 'GET',
      }),
      providesTags: ['Interview', 'InterviewApproval'],
      keepUnusedDataFor: 5,
    }),
    interview: builder.query({
      query: () => ({
        url: '/interview',
        method: 'GET',
      }),
      providesTags: ['Interview'],
      keepUnusedDataFor: 5,
    }),
    interviewApproval: builder.query({
      query: () => ({
        url: '/interview/approval',
        method: 'GET',
      }),
      providesTags: ['InterviewApproval'],
      keepUnusedDataFor: 5,
    }),

    candInterviewAlllist: builder.query({
      query: (id) => ({
        url: `interview/candidates/${id}`,
        method: 'GET',
      }),
      providesTags: ['candidatelistInterview'],
      keepUnusedDataFor: 5,
    }),
    getInterviewCandDetails: builder.query({
      query: (id) => ({
        url: `/interview/candidate/${id}`,
        method: 'GET',
      }),
      providesTags: ['candidatelistInterview'],
      keepUnusedDataFor: 5,
    }),

    scheduleInterview: builder.mutation({
      query: (data) => ({
        url: `/interview/candidate/${data.candid}/schedule`,
        method: 'POST',
        body: { ...data },
      }),
      invalidatesTags: [
        'Interview',
        'candidatelistInterview',
        'InterviewApproval',
      ],
    }),
    addScheduleInterview: builder.mutation({  //code for tist
      query: (data) => ({
        url: `/interview/candidate/${data.candid}/addSchedule`,
        method: 'POST',
        body: { ...data },
      }),
      invalidatesTags: [
        'Interview',
        'candidatelistInterview',
        'InterviewApproval',
      ],
    }),
    acceptInterview: builder.mutation({
      query: (data) => ({
        url: `/interview/interviewer/candidate/${data.id}/accept/${data.interviewid}`,
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: [
        'Interview',
        'candidatelistInterview',
        'InterviewApproval',
      ],
    }),
    rejectInterview: builder.mutation({
      query: (data) => ({
        url: `/interview/interviewer/candidate/${data.id}/reject/${data.interviewid}`,
        method: 'PUT',
        // body: { ...data },
      }),
      invalidatesTags: [
        'Interview',
        'candidatelistInterview',
        'InterviewApproval',
      ],
    }),
    interviewerUpdate: builder.mutation({
      query: (data) => ({
        url: `/interview/interviewer/candidate/${data.id}/update/${data.interviewid}`,
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: [
        'Interview',
        'candidatelistInterview',
        'InterviewApproval',
      ],
    }),
    interviewerQuery: builder.mutation({
      query: (data) => ({
        url: `/interview/interviewer/candidate/${data.id}/query/${data.interviewid}`,
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: [
        'Interview',
        'candidatelistInterview',
        'InterviewApproval',
      ],
    }),
    interviewerReschedule: builder.mutation({
      query: (data) => ({
        url: `/interview/candidate/${data.id}/re-schedule/${data.interviewid}`,
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: [
        'Interview',
        'candidatelistInterview',
        'InterviewApproval',
      ],
    }),
    // **** code by tist
    deleteInterviewer: builder.mutation({
      query: (id) => ({
        url: `/interview/interviewer/interviewerRemove/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['interviewerRemove'],
    }),
    deleteCandidate: builder.mutation({
      query: (id) => ({
        url: `/interview/interviewer/candidateRemove/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['candidateRemove'],
    }),
    interviewerCandDetails: builder.query({
      query: (id) => ({
        url: `/interview/interviewer/candidate/${id}`,
        method: 'GET',
      }),
      providesTags: ['candidatelistInterview'],
      keepUnusedDataFor: 5,
    }),
    skillsUpdate: builder.mutation({
      query: (data) => ({
        url: `/interview/interviewer/candidate/${data.id}/skillUpdate/${data.interviewid}`,
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: [
        'Interview',
        'candidatelistInterview',
        'InterviewApproval',
      ],
    }),
    getCandInterviewListDetails: builder.query({
      query: (id) => ({
        url: `/interview/candInterviewList/${id}`,
        method: 'GET',
      }),
      providesTags: ['candidatelistInterview'],
      keepUnusedDataFor: 5,
    }),
    candMultipleInterAlllist: builder.query({
      query: (id) => ({
        url: `interview/multipleInterviewer/${id}`,
        method: 'GET',
      }),
      providesTags: ['candidatelistMultimpleInterview'],
      keepUnusedDataFor: 5,
    }),
    interviewReschedule: builder.mutation({
      query: (data) => ({
        url: `/interview/candidate/re-schedule-interview/${data.interviewid}`,
        method: 'PUT',
        body: { ...data },
      }),
      invalidatesTags: [
        'Interview',
        'candidatelistInterview',
        'InterviewApproval',
      ],
    }),
    // code for tist
    confirmCandidate: builder.mutation({
      query: (confirmationData) => ({
        url: '/interview/candidateConfirm',
        method: 'POST',
        body: confirmationData, // Sending the candidateId and response
      }),
      invalidatesTags: ['Confirmation'],
    }),
  }),
});

export const {
  useInterviewerCandListQuery,
  useInterviewQuery,
  useInterviewApprovalQuery,
  useCandInterviewAlllistQuery,
  useGetInterviewCandDetailsQuery,
  useScheduleInterviewMutation,
  useAddScheduleInterviewMutation,
  useAcceptInterviewMutation,
  useRejectInterviewMutation,
  useInterviewerUpdateMutation,
  useInterviewerQueryMutation,
  useInterviewerRescheduleMutation,
  useDeleteInterviewerMutation,
  useInterviewerCandDetailsQuery,
  useSkillsUpdateMutation,
  useGetCandInterviewListDetailsQuery,
  useCandMultipleInterAlllistQuery,
  useDeleteCandidateMutation,
  useInterviewRescheduleMutation,
  useConfirmCandidateMutation
} = interviewApiSlice;
