import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../assets/images/logo.jpg';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useLoginApiMutation } from '../../features/auth/authApiSlice';
import { useDispatch } from 'react-redux';
import { setCredentials } from '../../features/auth/authSlice';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import LoadingIcon from '../../components/common/LoadingIcon';

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loginApi, { isLoading }] = useLoginApiMutation();
  const [errMsg, setErrMsg] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = { email: '', password: '' };
  const validationSchema = yup.object({
    email: yup
      .string()
      .email('Please enter a valid email')
      .required('Please enter email id'),
    password: yup.string().required('Please enter the password'),
  });

  const onSubmit = async (validationSchema) => {
    try {
      const userData = await loginApi(validationSchema).unwrap();
      dispatch(setCredentials({ ...userData }));
      if (userData.user.email == 'mahesh@email.com') {
        navigate('/app/interviewrequest ');
      } else {
        navigate('/app/dashboard');
      }
    } catch (error) {
      if (!error) {
        setErrMsg('No Server Response');
      } else if (error.status === 401) {
        console.log(error.data.message)
        setErrMsg(error.data.message);
      } else if (error.status === 404) {
        console.log(error.data.message)
        setErrMsg(error.data.message);
      } else {
        console.log(error)
        setErrMsg('Login Failed');
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    return () => { };
  }, []);

  useEffect(() => {
    setErrMsg('');
    return () => { };
  }, [formik.values.email, formik.values.password]);

  return (
    <>
      <div className="flex min-h-full bg-bodybg flex-col justify-center py-12 sm:px-6 lg:px-8 h-screen">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="flex justify-center mb-8">
              <img className="" src={Logo} alt="Rsaas" />
            </div>
            <h2 className="mb-3 text-3xl font-bold tracking-tight text-gray-900">
              Sign In
            </h2>

            <p className="mt-2 text-left text-sm text-gray-600 mb-6 font-semibold">
              Don't have a company account?{' '}
              <Link
                to={'/register'}
                className="text-indigo-600 hover:text-indigo-500 font-semibold"
              >
                Create Now
              </Link>
            </p>
            <div className="mt-2 text-sm text-red-600">{errMsg}</div>
            <form className="space-y-6" onSubmit={formik.handleSubmit}>
              <div className="space-y-1">
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Email Id *"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className="mt-2 text-sm text-red-600">
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>

              <div className="space-y-1 relative">
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  type={showPassword ? 'type' : 'password'}
                  name="password"
                  id="password"
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  placeholder="Password *"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                />
                {formik.values.password && (
                  <div
                    className=" cursor-pointer absolute top-1 right-0  items-center pr-3"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <EyeIcon
                        className="h-5 w-5 text-clickhere"
                        aria-hidden="true"
                      />
                    ) : (
                      <EyeSlashIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    )}
                  </div>
                )}
                {formik.touched.password && formik.errors.password ? (
                  <div className="mt-2 text-sm text-red-600">
                    {formik.errors.password}
                  </div>
                ) : null}
              </div>

              <div>
                {isLoading ? (
                  <button
                    type="submit"
                    disabled
                    className="flex w-full justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-4 text-sm font-medium text-white shadow-sm focus:outline-none "
                  >
                    <LoadingIcon />
                    Loading...
                  </button>
                ) : (
                  <button
                    type="submit"
                    disabled={!(formik.dirty && formik.isValid)}
                    className="flex w-full justify-center rounded-3xl border border-transparent bg-violet-900 py-3 px-4 text-sm font-medium text-white shadow-sm hover:bg-violet-700 disabled:bg-violet-400 focus:outline-none "
                  >
                    Sign in
                  </button>
                )}
              </div>
            </form>

            <div className="text-sm text-center pt-8">
              <Link
                to={'/forgotpassword'}
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                Forgot Password?
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
